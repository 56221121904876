/* eslint-disable */

// This is a generated file and SHOULD NOT BE EDITED MANUALLY!!
// Contents are generated as part of grunt build
// Source of truth is lib/cdo/global.rb and files in config/global/

export const RegionEn = {
  "locales": [
    "en-US"
  ],
  "header": {
    "top": {
      "signed_out": [
        {
          "title": "learn",
          "url": "/students",
          "domain": "code.org",
          "id": "header-learn"
        },
        {
          "title": "teach",
          "url": "/teach",
          "domain": "code.org",
          "id": "header-teach"
        },
        {
          "title": "districts",
          "url": "/administrators",
          "domain": "code.org",
          "id": "header-districts"
        },
        {
          "title": "stats",
          "url": "/promote",
          "domain": "code.org",
          "id": "header-stats"
        },
        {
          "title": "help_us",
          "url": "/help",
          "domain": "code.org",
          "id": "header-help"
        },
        {
          "title": "incubator",
          "url": "/incubator",
          "domain": "studio.code.org",
          "id": "header-incubator"
        },
        {
          "title": "about",
          "url": "/about",
          "domain": "code.org",
          "id": "header-about"
        }
      ],
      "student": [
        {
          "title": "my_dashboard",
          "url": "/home",
          "domain": "studio.code.org",
          "id": "header-student-home"
        },
        {
          "title": "course_catalog",
          "url": "/students",
          "domain": "code.org",
          "id": "header-student-courses"
        },
        {
          "title": "project_gallery",
          "url": "/projects",
          "domain": "studio.code.org",
          "id": "header-student-projects"
        },
        {
          "title": "incubator",
          "url": "/incubator",
          "domain": "studio.code.org",
          "id": "header-incubator"
        }
      ],
      "teacher": [
        {
          "title": "my_dashboard",
          "url": "/home",
          "domain": "studio.code.org",
          "id": "header-teacher-home"
        },
        {
          "title": "course_catalog",
          "url": "/catalog",
          "domain": "studio.code.org",
          "id": "header-teacher-courses"
        },
        {
          "title": "project_gallery",
          "url": "/projects",
          "domain": "studio.code.org",
          "id": "header-teacher-projects"
        },
        {
          "title": "professional_learning",
          "url": "/my-professional-learning",
          "domain": "studio.code.org",
          "id": "header-teacher-professional-learning"
        },
        {
          "title": "incubator",
          "url": "/incubator",
          "domain": "studio.code.org",
          "id": "header-teacher-incubator"
        }
      ]
    },
    "hamburger": {
      "student": [
        {
          "title": "learn",
          "domain": "code.org",
          "url": "/students",
          "id": "learn"
        },
        {
          "title": "teach",
          "type": "expander",
          "id": "educate_entries",
          "subentries": [
            {
              "title": "educate_overview",
              "domain": "code.org",
              "url": "/teach",
              "id": "educate-overview"
            },
            {
              "title": "course_catalog",
              "url": "/students",
              "domain": "code.org",
              "id": "educate_courses"
            },
            {
              "title": "educate_elementary",
              "domain": "code.org",
              "url": "/educate/curriculum/elementary-school"
            },
            {
              "title": "educate_middle",
              "domain": "code.org",
              "url": "/educate/curriculum/middle-school"
            },
            {
              "title": "educate_high",
              "domain": "code.org",
              "url": "/educate/curriculum/high-school"
            },
            {
              "title": "educate_hoc",
              "url": "https://hourofcode.com"
            },
            {
              "title": "educate_beyond",
              "domain": "code.org",
              "url": "/educate/curriculum/3rd-party"
            },
            {
              "title": "educate_community",
              "url": "https://forum.code.org/"
            },
            {
              "title": "educate_requirements",
              "domain": "code.org",
              "url": "/educate/it"
            },
            {
              "title": "educate_tools",
              "domain": "code.org",
              "url": "/educate/resources/videos"
            }
          ]
        },
        {
          "title": "districts",
          "domain": "code.org",
          "url": "/administrators",
          "id": "districts"
        },
        {
          "title": "stats",
          "domain": "code.org",
          "url": "/promote",
          "id": "stats"
        },
        {
          "title": "help_us",
          "domain": "code.org",
          "url": "/help",
          "id": "help-us"
        },
        {
          "title": "incubator",
          "url": "/incubator",
          "domain": "studio.code.org",
          "id": "incubator"
        },
        {
          "title": "about",
          "type": "expander",
          "id": "about_entries",
          "subentries": [
            {
              "title": "about_us",
              "domain": "code.org",
              "url": "/about",
              "id": "about-us"
            },
            {
              "title": "about_leadership",
              "domain": "code.org",
              "url": "/about/leadership"
            },
            {
              "title": "about_donors",
              "domain": "code.org",
              "url": "/about/donors"
            },
            {
              "title": "about_partners",
              "domain": "code.org",
              "url": "/about/partners"
            },
            {
              "title": "about_team",
              "domain": "code.org",
              "url": "/about/team"
            },
            {
              "title": "about_news",
              "domain": "code.org",
              "url": "/about/news"
            },
            {
              "title": "about_jobs",
              "domain": "code.org",
              "url": "/about/jobs"
            },
            {
              "title": "about_contact",
              "domain": "code.org",
              "url": "/contact"
            },
            {
              "title": "about_faqs",
              "domain": "code.org",
              "url": "/faq"
            }
          ]
        },
        {
          "title": "legal",
          "type": "expander",
          "id": "legal_entries",
          "subentries": [
            {
              "title": "legal_privacy",
              "domain": "code.org",
              "url": "/privacy"
            },
            {
              "title": "legal_cookie_notice",
              "domain": "code.org",
              "url": "/cookies"
            },
            {
              "title": "legal_tos",
              "domain": "code.org",
              "url": "/tos"
            }
          ]
        }
      ],
      "teacher": [
        {
          "title": "learn",
          "domain": "code.org",
          "url": "/students",
          "id": "learn"
        },
        {
          "title": "teach",
          "type": "expander",
          "id": "educate_entries",
          "subentries": [
            {
              "title": "educate_overview",
              "domain": "code.org",
              "url": "/teach",
              "id": "educate-overview"
            },
            {
              "title": "course_catalog",
              "url": "/catalog",
              "domain": "studio.code.org",
              "id": "educate_courses"
            },
            {
              "title": "educate_elementary",
              "domain": "code.org",
              "url": "/educate/curriculum/elementary-school"
            },
            {
              "title": "educate_middle",
              "domain": "code.org",
              "url": "/educate/curriculum/middle-school"
            },
            {
              "title": "educate_high",
              "domain": "code.org",
              "url": "/educate/curriculum/high-school"
            },
            {
              "title": "educate_hoc",
              "url": "https://hourofcode.com"
            },
            {
              "title": "educate_beyond",
              "domain": "code.org",
              "url": "/educate/curriculum/3rd-party"
            },
            {
              "title": "educate_community",
              "url": "https://forum.code.org/"
            },
            {
              "title": "educate_requirements",
              "domain": "code.org",
              "url": "/educate/it"
            },
            {
              "title": "educate_tools",
              "domain": "code.org",
              "url": "/educate/resources/videos"
            }
          ]
        },
        {
          "title": "districts",
          "domain": "code.org",
          "url": "/administrators",
          "id": "districts"
        },
        {
          "title": "stats",
          "domain": "code.org",
          "url": "/promote",
          "id": "stats"
        },
        {
          "title": "help_us",
          "domain": "code.org",
          "url": "/help",
          "id": "help-us"
        },
        {
          "title": "incubator",
          "url": "/incubator",
          "domain": "studio.code.org",
          "id": "incubator"
        },
        {
          "title": "about",
          "type": "expander",
          "id": "about_entries",
          "subentries": [
            {
              "title": "about_us",
              "domain": "code.org",
              "url": "/about",
              "id": "about-us"
            },
            {
              "title": "about_leadership",
              "domain": "code.org",
              "url": "/about/leadership"
            },
            {
              "title": "about_donors",
              "domain": "code.org",
              "url": "/about/donors"
            },
            {
              "title": "about_partners",
              "domain": "code.org",
              "url": "/about/partners"
            },
            {
              "title": "about_team",
              "domain": "code.org",
              "url": "/about/team"
            },
            {
              "title": "about_news",
              "domain": "code.org",
              "url": "/about/news"
            },
            {
              "title": "about_jobs",
              "domain": "code.org",
              "url": "/about/jobs"
            },
            {
              "title": "about_contact",
              "domain": "code.org",
              "url": "/contact"
            },
            {
              "title": "about_faqs",
              "domain": "code.org",
              "url": "/faq"
            }
          ]
        },
        {
          "title": "legal",
          "type": "expander",
          "id": "legal_entries",
          "subentries": [
            {
              "title": "legal_privacy",
              "domain": "code.org",
              "url": "/privacy"
            },
            {
              "title": "legal_cookie_notice",
              "domain": "code.org",
              "url": "/cookies"
            },
            {
              "title": "legal_tos",
              "domain": "code.org",
              "url": "/tos"
            }
          ]
        }
      ]
    },
    "help": [
      {
        "title": "game_lab_documentation",
        "domain": "studio.code.org",
        "level": "Gamelab",
        "url": "/docs/gamelab",
        "id": "gamelab-docs"
      },
      {
        "title": "game_lab_tutorials",
        "domain": "code.org",
        "level": "Gamelab",
        "url": "/educate/gamelab",
        "id": "gamelab-tutorials"
      },
      {
        "title": "app_lab_documentation",
        "domain": "studio.code.org",
        "level": "Applab",
        "url": "/docs/applab",
        "id": "applab-docs"
      },
      {
        "title": "app_lab_tutorials",
        "domain": "code.org",
        "level": "Applab",
        "url": "/educate/applab",
        "id": "applab-tutorials"
      },
      {
        "title": "sprite_lab_documentation",
        "domain": "studio.code.org",
        "level": "Spritelab",
        "url": "/docs/spritelab",
        "id": "spritelab-docs"
      },
      {
        "title": "sprite_lab_tutorials",
        "domain": "code.org",
        "level": "Spritelab",
        "url": "/educate/spritelab",
        "id": "spritelab-tutorials"
      },
      {
        "title": "web_lab_documentation",
        "domain": "studio.code.org",
        "level": "Weblab",
        "url": "/docs/ide/weblab",
        "id": "weblab-docs"
      },
      {
        "title": "music_lab_documentation",
        "domain": "studio.code.org",
        "level": "Music",
        "url": "/docs/ide/music",
        "id": "musiclab-docs"
      },
      {
        "title": "help_support",
        "url": "https://support.code.org",
        "id": "support"
      },
      {
        "title": "report_bug",
        "url": "https://support.code.org/hc/en-us/requests/new",
        "id": "report-bug"
      },
      {
        "title": "report_abuse",
        "domain": "studio.code.org",
        "url": "/report_abuse",
        "id": "report-abuse"
      },
      {
        "title": "teacher_community",
        "user_type": "teacher",
        "url": "https://forum.code.org",
        "id": "teacher-community"
      }
    ]
  },
  "footer": {
    "links": {
      "studio": [
        {
          "title": "privacy",
          "url": "/privacy",
          "domain": "code.org",
          "style": "color: #ffa400;"
        },
        {
          "title": "cookie_notice",
          "url": "/cookies",
          "domain": "code.org"
        },
        {
          "title": "translate",
          "url": "/translate",
          "domain": "code.org"
        },
        {
          "title": "help_support",
          "url": "https://support.code.org",
          "target": "_blank",
          "loc_prefix": "landing.",
          "id": "support"
        },
        {
          "title": "store",
          "url": "https://store.code.org"
        },
        {
          "title": "tos_short",
          "url": "/tos",
          "domain": "code.org"
        }
      ],
      "code_org": [
        {
          "title": "privacy_policy",
          "url": "/privacy",
          "domain": "code.org",
          "style": "color: #ffa400;"
        },
        {
          "title": "cookie_notice",
          "url": "/cookies",
          "domain": "code.org"
        },
        {
          "title": "about",
          "url": "/about",
          "domain": "code.org"
        },
        {
          "title": "partners",
          "url": "/partners",
          "domain": "code.org"
        },
        {
          "title": "blog",
          "url": "https://medium.com/@codeorg",
          "target": "_blank"
        },
        {
          "title": "donate",
          "url": "/donate",
          "domain": "code.org"
        },
        {
          "title": "store",
          "url": "shop",
          "domain": "code.org"
        },
        {
          "title": "support",
          "url": "http://support.code.org/",
          "target": "_blank"
        },
        {
          "title": "terms",
          "url": "/tos",
          "domain": "code.org"
        }
      ],
      "hour_of_code": [
        {
          "title": "contact",
          "url": "http://support.code.org/hc/en-us/requests/new",
          "target": "_blank"
        },
        {
          "title": "support",
          "url": "http://support.code.org",
          "target": "_blank"
        },
        {
          "title": "terms",
          "url": "/tos",
          "domain": "code.org",
          "target": "_blank"
        },
        {
          "title": "privacy",
          "url": "/privacy",
          "domain": "code.org",
          "target": "_blank"
        },
        {
          "title": "cookie",
          "domain": "hourofcode.com",
          "url": "/cookies",
          "target": "_blank"
        }
      ]
    }
  }
} as const;

export const RegionFa = {
  "locales": [
    "fa-IR",
    "en-US"
  ],
  "locale_lock": true,
  "countries": [
    "IR"
  ],
  "project_types": [
    "spritelab",
    "artist",
    "applab",
    "gamelab"
  ],
  "header": {
    "top": {
      "signed_out": [
        {
          "title": "teach",
          "id": "header-teach",
          "domain": "code.org",
          "url": "/global/fa/teacher"
        },
        {
          "title": "about",
          "id": "header-about",
          "domain": "code.org",
          "url": "/global/fa/about"
        },
        {
          "title": "csf",
          "id": "header-csf",
          "domain": "code.org",
          "url": "/global/fa/csf"
        },
        {
          "title": "hour_of_code",
          "id": "header-hoc",
          "domain": "code.org",
          "url": "/global/fa/hourofcode"
        },
        {
          "title": "videos",
          "id": "header-videos",
          "domain": "code.org",
          "url": "/global/fa/videos"
        }
      ],
      "student": [
        {
          "title": "my_dashboard",
          "url": "/global/fa/home",
          "domain": "studio.code.org",
          "id": "header-student-home"
        },
        {
          "title": "course_catalog",
          "url": "/global/fa/catalog",
          "domain": "studio.code.org",
          "id": "header-student-courses"
        }
      ],
      "teacher": [
        {
          "title": "my_dashboard",
          "url": "/global/fa/home",
          "domain": "studio.code.org",
          "id": "header-student-home"
        },
        {
          "title": "course_catalog",
          "url": "/global/fa/catalog",
          "domain": "studio.code.org",
          "id": "header-teacher-courses"
        },
        {
          "title": "professional_learning",
          "url": "/global/fa/my-professional-learning",
          "domain": "studio.code.org",
          "id": "header-teacher-professional-learning"
        }
      ]
    },
    "hamburger": {
      "student": [
        {
          "title": "learn",
          "domain": "studio.code.org",
          "url": "/global/fa/catalog",
          "id": "learn"
        },
        {
          "title": "legal",
          "type": "expander",
          "id": "legal_entries",
          "subentries": [
            {
              "title": "legal_privacy",
              "url": "https://code-org.translate.goog/privacy?_x_tr_sl=auto&_x_tr_tl=fa&_x_tr_hl=en&_x_tr_pto=wapp"
            },
            {
              "title": "legal_cookie_notice",
              "url": "https://code-org.translate.goog/privacy/cookies?_x_tr_sl=auto&_x_tr_tl=fa&_x_tr_hl=en&_x_tr_pto=wapp"
            },
            {
              "title": "legal_tos",
              "url": "https://code-org.translate.goog/tos?_x_tr_sl=auto&_x_tr_tl=fa&_x_tr_hl=en&_x_tr_pto=wapp"
            }
          ]
        }
      ],
      "teacher": [
        {
          "title": "learn",
          "domain": "studio.code.org",
          "url": "/global/fa/catalog",
          "id": "learn"
        },
        {
          "title": "legal",
          "type": "expander",
          "id": "legal_entries",
          "subentries": [
            {
              "title": "legal_privacy",
              "url": "https://code-org.translate.goog/privacy?_x_tr_sl=auto&_x_tr_tl=fa&_x_tr_hl=en&_x_tr_pto=wapp"
            },
            {
              "title": "legal_cookie_notice",
              "url": "https://code-org.translate.goog/privacy/cookies?_x_tr_sl=auto&_x_tr_tl=fa&_x_tr_hl=en&_x_tr_pto=wapp"
            },
            {
              "title": "legal_tos",
              "url": "https://code-org.translate.goog/tos?_x_tr_sl=auto&_x_tr_tl=fa&_x_tr_hl=en&_x_tr_pto=wapp"
            }
          ]
        }
      ]
    },
    "help": [
      {
        "title": "report_bug",
        "url": "https://support.code.org/hc/ir-fa/requests/new",
        "id": "report-bug"
      }
    ]
  },
  "footer": {
    "links": {
      "studio": [
        {
          "title": "privacy",
          "url": "https://code-org.translate.goog/privacy?_x_tr_sl=auto&_x_tr_tl=fa&_x_tr_hl=en&_x_tr_pto=wapp",
          "style": "color: #ffa400;"
        },
        {
          "title": "cookie_notice",
          "url": "https://code-org.translate.goog/privacy/cookies?_x_tr_sl=auto&_x_tr_tl=fa&_x_tr_hl=en&_x_tr_pto=wapp"
        },
        {
          "title": "tos_short",
          "url": "https://code-org.translate.goog/tos?_x_tr_sl=auto&_x_tr_tl=fa&_x_tr_hl=en&_x_tr_pto=wapp"
        }
      ],
      "code_org": [
        {
          "title": "privacy_policy",
          "url": "https://code-org.translate.goog/privacy?_x_tr_sl=auto&_x_tr_tl=fa&_x_tr_hl=en&_x_tr_pto=wapp"
        },
        {
          "title": "terms",
          "url": "https://code-org.translate.goog/tos?_x_tr_sl=auto&_x_tr_tl=fa&_x_tr_hl=en&_x_tr_pto=wapp"
        }
      ],
      "hour_of_code": [
        {
          "title": "terms",
          "url": "https://code-org.translate.goog/tos?_x_tr_sl=auto&_x_tr_tl=fa&_x_tr_hl=en&_x_tr_pto=wapp",
          "target": "_blank"
        },
        {
          "title": "privacy",
          "url": "https://code-org.translate.goog/privacy?_x_tr_sl=auto&_x_tr_tl=fa&_x_tr_hl=en&_x_tr_pto=wapp",
          "target": "_blank"
        },
        {
          "title": "cookie",
          "url": "https://code-org.translate.goog/privacy/cookies?_x_tr_sl=auto&_x_tr_tl=fa&_x_tr_hl=en&_x_tr_pto=wapp",
          "target": "_blank"
        }
      ]
    }
  },
  "pages": [
    {
      "path": "/",
      "components": {
        "LtiFeedbackBanner": false,
        "CurriculumCatalog": {
          "forceTranslated": true
        },
        "StartNewProject": {
          "availableProjectTypes": [
            "spritelab",
            "artist",
            "applab",
            "gamelab"
          ]
        },
        "CurriculumQuickAssign": {
          "courseFilters": {
            "language": "fa-IR"
          }
        },
        "AssignmentVersionSelector": {
          "courseFilters": {
            "language": "fa-IR"
          }
        }
      }
    },
    {
      "path": "/teacher_dashboard",
      "components": {
        "DownloadParentLetterButton": false,
        "ManageStudentsLoginInfo": {
          "providePrivacyLetter": false
        },
        "SectionProgressSelector": {
          "showProgressTableV2": true
        },
        "ProgressV1OrV2ToggleLink": false,
        "ProgressFeedbackBanner": false
      }
    },
    {
      "path": "/home",
      "components": {
        "MarketingAnnouncementBanner": false,
        "TeacherResources": false,
        "IncubatorBanner": false
      }
    },
    {
      "path": "/my-professional-learning",
      "components": {
        "LandingPage": {
          "hideMyFacilitatorCenterTab": true,
          "hideInstructorCenterTab": false,
          "hideRPCenterTab": true,
          "hideWorkshopOrganizerCenterTab": true,
          "hideMyPLWorkshopEnrollmentCelebrationDialog": true,
          "hideMyPLBanner": true,
          "hideMyPLSelfPacedPL": false,
          "hideMyPLJoinSectionArea": true,
          "hideMyPLLandingPageWorkshopsTable": true,
          "hideMyPLStaticRecommendedPL": false,
          "hideMyPLStaticRecommendedPLMidHighBlock": true,
          "hideMyPLStaticRecommendedPLSelfPacedBlock": false,
          "myPLStaticRecommendedPLSelfPacedBlockButtonUrl": "/global/fa/teacher"
        }
      }
    }
  ]
} as const;

export const RegionRoot = {
  "locales": [
    "en-US"
  ],
  "header": {
    "top": {
      "signed_out": [
        {
          "title": "learn",
          "url": "/students",
          "domain": "code.org",
          "id": "header-learn"
        },
        {
          "title": "teach",
          "url": "/teach",
          "domain": "code.org",
          "id": "header-teach"
        },
        {
          "title": "districts",
          "url": "/administrators",
          "domain": "code.org",
          "id": "header-districts"
        },
        {
          "title": "stats",
          "url": "/promote",
          "domain": "code.org",
          "id": "header-stats"
        },
        {
          "title": "help_us",
          "url": "/help",
          "domain": "code.org",
          "id": "header-help"
        },
        {
          "title": "incubator",
          "url": "/incubator",
          "domain": "studio.code.org",
          "id": "header-incubator"
        },
        {
          "title": "about",
          "url": "/about",
          "domain": "code.org",
          "id": "header-about"
        }
      ],
      "student": [
        {
          "title": "my_dashboard",
          "url": "/home",
          "domain": "studio.code.org",
          "id": "header-student-home"
        },
        {
          "title": "course_catalog",
          "url": "/students",
          "domain": "code.org",
          "id": "header-student-courses"
        },
        {
          "title": "project_gallery",
          "url": "/projects",
          "domain": "studio.code.org",
          "id": "header-student-projects"
        },
        {
          "title": "incubator",
          "url": "/incubator",
          "domain": "studio.code.org",
          "id": "header-incubator"
        }
      ],
      "teacher": [
        {
          "title": "my_dashboard",
          "url": "/home",
          "domain": "studio.code.org",
          "id": "header-teacher-home"
        },
        {
          "title": "course_catalog",
          "url": "/catalog",
          "domain": "studio.code.org",
          "id": "header-teacher-courses"
        },
        {
          "title": "project_gallery",
          "url": "/projects",
          "domain": "studio.code.org",
          "id": "header-teacher-projects"
        },
        {
          "title": "professional_learning",
          "url": "/my-professional-learning",
          "domain": "studio.code.org",
          "id": "header-teacher-professional-learning"
        },
        {
          "title": "incubator",
          "url": "/incubator",
          "domain": "studio.code.org",
          "id": "header-teacher-incubator"
        }
      ]
    },
    "hamburger": {
      "student": [
        {
          "title": "learn",
          "domain": "code.org",
          "url": "/students",
          "id": "learn"
        },
        {
          "title": "teach",
          "type": "expander",
          "id": "educate_entries",
          "subentries": [
            {
              "title": "educate_overview",
              "domain": "code.org",
              "url": "/teach",
              "id": "educate-overview"
            },
            {
              "title": "course_catalog",
              "url": "/students",
              "domain": "code.org",
              "id": "educate_courses"
            },
            {
              "title": "educate_elementary",
              "domain": "code.org",
              "url": "/educate/curriculum/elementary-school"
            },
            {
              "title": "educate_middle",
              "domain": "code.org",
              "url": "/educate/curriculum/middle-school"
            },
            {
              "title": "educate_high",
              "domain": "code.org",
              "url": "/educate/curriculum/high-school"
            },
            {
              "title": "educate_hoc",
              "url": "https://hourofcode.com"
            },
            {
              "title": "educate_beyond",
              "domain": "code.org",
              "url": "/educate/curriculum/3rd-party"
            },
            {
              "title": "educate_community",
              "url": "https://forum.code.org/"
            },
            {
              "title": "educate_requirements",
              "domain": "code.org",
              "url": "/educate/it"
            },
            {
              "title": "educate_tools",
              "domain": "code.org",
              "url": "/educate/resources/videos"
            }
          ]
        },
        {
          "title": "districts",
          "domain": "code.org",
          "url": "/administrators",
          "id": "districts"
        },
        {
          "title": "stats",
          "domain": "code.org",
          "url": "/promote",
          "id": "stats"
        },
        {
          "title": "help_us",
          "domain": "code.org",
          "url": "/help",
          "id": "help-us"
        },
        {
          "title": "incubator",
          "url": "/incubator",
          "domain": "studio.code.org",
          "id": "incubator"
        },
        {
          "title": "about",
          "type": "expander",
          "id": "about_entries",
          "subentries": [
            {
              "title": "about_us",
              "domain": "code.org",
              "url": "/about",
              "id": "about-us"
            },
            {
              "title": "about_leadership",
              "domain": "code.org",
              "url": "/about/leadership"
            },
            {
              "title": "about_donors",
              "domain": "code.org",
              "url": "/about/donors"
            },
            {
              "title": "about_partners",
              "domain": "code.org",
              "url": "/about/partners"
            },
            {
              "title": "about_team",
              "domain": "code.org",
              "url": "/about/team"
            },
            {
              "title": "about_news",
              "domain": "code.org",
              "url": "/about/news"
            },
            {
              "title": "about_jobs",
              "domain": "code.org",
              "url": "/about/jobs"
            },
            {
              "title": "about_contact",
              "domain": "code.org",
              "url": "/contact"
            },
            {
              "title": "about_faqs",
              "domain": "code.org",
              "url": "/faq"
            }
          ]
        },
        {
          "title": "legal",
          "type": "expander",
          "id": "legal_entries",
          "subentries": [
            {
              "title": "legal_privacy",
              "domain": "code.org",
              "url": "/privacy"
            },
            {
              "title": "legal_cookie_notice",
              "domain": "code.org",
              "url": "/cookies"
            },
            {
              "title": "legal_tos",
              "domain": "code.org",
              "url": "/tos"
            }
          ]
        }
      ],
      "teacher": [
        {
          "title": "learn",
          "domain": "code.org",
          "url": "/students",
          "id": "learn"
        },
        {
          "title": "teach",
          "type": "expander",
          "id": "educate_entries",
          "subentries": [
            {
              "title": "educate_overview",
              "domain": "code.org",
              "url": "/teach",
              "id": "educate-overview"
            },
            {
              "title": "course_catalog",
              "url": "/catalog",
              "domain": "studio.code.org",
              "id": "educate_courses"
            },
            {
              "title": "educate_elementary",
              "domain": "code.org",
              "url": "/educate/curriculum/elementary-school"
            },
            {
              "title": "educate_middle",
              "domain": "code.org",
              "url": "/educate/curriculum/middle-school"
            },
            {
              "title": "educate_high",
              "domain": "code.org",
              "url": "/educate/curriculum/high-school"
            },
            {
              "title": "educate_hoc",
              "url": "https://hourofcode.com"
            },
            {
              "title": "educate_beyond",
              "domain": "code.org",
              "url": "/educate/curriculum/3rd-party"
            },
            {
              "title": "educate_community",
              "url": "https://forum.code.org/"
            },
            {
              "title": "educate_requirements",
              "domain": "code.org",
              "url": "/educate/it"
            },
            {
              "title": "educate_tools",
              "domain": "code.org",
              "url": "/educate/resources/videos"
            }
          ]
        },
        {
          "title": "districts",
          "domain": "code.org",
          "url": "/administrators",
          "id": "districts"
        },
        {
          "title": "stats",
          "domain": "code.org",
          "url": "/promote",
          "id": "stats"
        },
        {
          "title": "help_us",
          "domain": "code.org",
          "url": "/help",
          "id": "help-us"
        },
        {
          "title": "incubator",
          "url": "/incubator",
          "domain": "studio.code.org",
          "id": "incubator"
        },
        {
          "title": "about",
          "type": "expander",
          "id": "about_entries",
          "subentries": [
            {
              "title": "about_us",
              "domain": "code.org",
              "url": "/about",
              "id": "about-us"
            },
            {
              "title": "about_leadership",
              "domain": "code.org",
              "url": "/about/leadership"
            },
            {
              "title": "about_donors",
              "domain": "code.org",
              "url": "/about/donors"
            },
            {
              "title": "about_partners",
              "domain": "code.org",
              "url": "/about/partners"
            },
            {
              "title": "about_team",
              "domain": "code.org",
              "url": "/about/team"
            },
            {
              "title": "about_news",
              "domain": "code.org",
              "url": "/about/news"
            },
            {
              "title": "about_jobs",
              "domain": "code.org",
              "url": "/about/jobs"
            },
            {
              "title": "about_contact",
              "domain": "code.org",
              "url": "/contact"
            },
            {
              "title": "about_faqs",
              "domain": "code.org",
              "url": "/faq"
            }
          ]
        },
        {
          "title": "legal",
          "type": "expander",
          "id": "legal_entries",
          "subentries": [
            {
              "title": "legal_privacy",
              "domain": "code.org",
              "url": "/privacy"
            },
            {
              "title": "legal_cookie_notice",
              "domain": "code.org",
              "url": "/cookies"
            },
            {
              "title": "legal_tos",
              "domain": "code.org",
              "url": "/tos"
            }
          ]
        }
      ]
    },
    "help": [
      {
        "title": "game_lab_documentation",
        "domain": "studio.code.org",
        "level": "Gamelab",
        "url": "/docs/gamelab",
        "id": "gamelab-docs"
      },
      {
        "title": "game_lab_tutorials",
        "domain": "code.org",
        "level": "Gamelab",
        "url": "/educate/gamelab",
        "id": "gamelab-tutorials"
      },
      {
        "title": "app_lab_documentation",
        "domain": "studio.code.org",
        "level": "Applab",
        "url": "/docs/applab",
        "id": "applab-docs"
      },
      {
        "title": "app_lab_tutorials",
        "domain": "code.org",
        "level": "Applab",
        "url": "/educate/applab",
        "id": "applab-tutorials"
      },
      {
        "title": "sprite_lab_documentation",
        "domain": "studio.code.org",
        "level": "Spritelab",
        "url": "/docs/spritelab",
        "id": "spritelab-docs"
      },
      {
        "title": "sprite_lab_tutorials",
        "domain": "code.org",
        "level": "Spritelab",
        "url": "/educate/spritelab",
        "id": "spritelab-tutorials"
      },
      {
        "title": "web_lab_documentation",
        "domain": "studio.code.org",
        "level": "Weblab",
        "url": "/docs/ide/weblab",
        "id": "weblab-docs"
      },
      {
        "title": "music_lab_documentation",
        "domain": "studio.code.org",
        "level": "Music",
        "url": "/docs/ide/music",
        "id": "musiclab-docs"
      },
      {
        "title": "help_support",
        "url": "https://support.code.org",
        "id": "support"
      },
      {
        "title": "report_bug",
        "url": "https://support.code.org/hc/en-us/requests/new",
        "id": "report-bug"
      },
      {
        "title": "report_abuse",
        "domain": "studio.code.org",
        "url": "/report_abuse",
        "id": "report-abuse"
      },
      {
        "title": "teacher_community",
        "user_type": "teacher",
        "url": "https://forum.code.org",
        "id": "teacher-community"
      }
    ]
  },
  "footer": {
    "links": {
      "studio": [
        {
          "title": "privacy",
          "url": "/privacy",
          "domain": "code.org",
          "style": "color: #ffa400;"
        },
        {
          "title": "cookie_notice",
          "url": "/cookies",
          "domain": "code.org"
        },
        {
          "title": "translate",
          "url": "/translate",
          "domain": "code.org"
        },
        {
          "title": "help_support",
          "url": "https://support.code.org",
          "target": "_blank",
          "loc_prefix": "landing.",
          "id": "support"
        },
        {
          "title": "store",
          "url": "https://store.code.org"
        },
        {
          "title": "tos_short",
          "url": "/tos",
          "domain": "code.org"
        }
      ],
      "code_org": [
        {
          "title": "privacy_policy",
          "url": "/privacy",
          "domain": "code.org",
          "style": "color: #ffa400;"
        },
        {
          "title": "cookie_notice",
          "url": "/cookies",
          "domain": "code.org"
        },
        {
          "title": "about",
          "url": "/about",
          "domain": "code.org"
        },
        {
          "title": "partners",
          "url": "/partners",
          "domain": "code.org"
        },
        {
          "title": "blog",
          "url": "https://medium.com/@codeorg",
          "target": "_blank"
        },
        {
          "title": "donate",
          "url": "/donate",
          "domain": "code.org"
        },
        {
          "title": "store",
          "url": "shop",
          "domain": "code.org"
        },
        {
          "title": "support",
          "url": "http://support.code.org/",
          "target": "_blank"
        },
        {
          "title": "terms",
          "url": "/tos",
          "domain": "code.org"
        }
      ],
      "hour_of_code": [
        {
          "title": "contact",
          "url": "http://support.code.org/hc/en-us/requests/new",
          "target": "_blank"
        },
        {
          "title": "support",
          "url": "http://support.code.org",
          "target": "_blank"
        },
        {
          "title": "terms",
          "url": "/tos",
          "domain": "code.org",
          "target": "_blank"
        },
        {
          "title": "privacy",
          "url": "/privacy",
          "domain": "code.org",
          "target": "_blank"
        },
        {
          "title": "cookie",
          "domain": "hourofcode.com",
          "url": "/cookies",
          "target": "_blank"
        }
      ]
    }
  }
} as const;

export const Regions = {
  "en": RegionEn,
  "fa": RegionFa,
  "root": RegionRoot,
} as const;

